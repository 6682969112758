<template>
  <div class="dynamic">
    <div class="inbanner clearfix">
      <div class="swiper-box">
        <swiper :options="swiperOption"
                ref="mySwiper"
                class="swiper-wrapper"
                @slide-change-transition-end="handleClickSlide">
          <swiper-slide v-for="(item, index) in swiperList.items"
                        :key="index"
                        class="swioer-item"
                        swiper-animate-effect="shutter2">
            <div class="swiper-box">
              <img :src="item.imgs?(JSON.parse(item.imgs)[langtype]?JSON.parse(item.imgs)[langtype]:item.img):item.img" alt="" />
              <!-- <div :class="'center' + index">
              <p class="name">{{item.name}}</p>
              <p class="title">{{item.title}}</p>
              <p class="description">{{item.description}}</p>
              <div class="btn-box" v-if="index !== 0">
                <span>立即选购 </span>|
                <span>  了解详情</span>
              </div>
            </div> -->
            </div>
          </swiper-slide>
          <div class="swiper-pagination"
               slot="pagination"></div>
        </swiper>
        <!-- <div class="sign-box">
        <img class="sign" src="../assets/home/shubiao.png" alt="" />
      </div> -->
      </div>
    </div>
    <div class="clear"></div>
    <div class="center">
      <el-breadcrumb class="breadcrumb"
                     style="width:85%;margin:0 auto;"
                     separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">{{$t('dynamic.home')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{$t('dynamic.xwdt')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{location}}</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="news-box"
           style="width:85%;margin: 0 auto;">
        <div class="tabs">
          <ul>
            <li v-for="(item,index) in tabList"
                :class="[tabShou == index?'active':'']"
                @click="addTab(item,index)"
                :key="index">{{item.name}} </li>
          </ul>
        </div>
        <div class="news-cen"
             v-if="tabShou == 0">
          <dynamic-box v-if="dynamics"
                       :dynamics="dynamics"></dynamic-box>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage1"
                         :page-size="count"
                         layout="total, prev, pager, next"
                         :total="total">
          </el-pagination>
        </div>
        <div class="news-cen"
             v-if="tabShou == 1">
          <dynamic-box v-if="dynamics"
                       :dynamics="dynamics"></dynamic-box>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage1"
                         :page-size="count"
                         layout="total, prev, pager, next"
                         :total="total">
          </el-pagination>
        </div>
        <div class="news-cen"
             v-if="tabShou == 2">
          <dynamic-box v-if="dynamics"
                       :dynamics="dynamics"></dynamic-box>
          <el-pagination @size-change="handleSizeChange"
                         @current-change="handleCurrentChange"
                         :current-page.sync="currentPage1"
                         :page-size="count"
                         layout="total, prev, pager, next"
                         :total="total">
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import "swiper/dist/css/swiper.css";
import DynamicBox from '../components/common/dynamicBox.vue';
export default {
  props: [],
  data () {
    return {
      tabList: [
        { name: 'bbdt' },
        { name: 'hydt' },
        { name: 'bbbk' },
      ],
      location: '',
      tabShou: 0,
      dynamic: [],
      dynamics: [],
      currentPage1: 5,
      total: null,
      page: 1,
      count: 5,
      information: {},
      langtype: 0,
      swiperOption: {
        loop: true, // 是否循环轮播
        autoplay: true, // 是否可以自动轮播
        slidesPerView: 1, // 可是区域内可展示多少个块
        spaceBetween: 30, // 块之间间隔距离
        initialSlide: 0, // 默认初始显示块
        freeMode: false,
        // 显示分页
        pagination: {
          el: ".swiper-pagination",
          clickable: true,
        },
      },
      swiperList: [

      ],
    }
  },
  name: "dynamic",
  computed: {
    swiper () {
      return this.$refs.mySwiper.swiper;
    },
  },
  components: { swiper, swiperSlide, DynamicBox },
  created () {
    switch (localStorage.getItem('lang')) {
      case "zh":
        this.langtype = 0;
        break;
      case "en":
        this.langtype = 1;
        break;
      case "es":
        this.langtype = 6;
        break;
      case "ru":
        this.langtype = 2;
        break;
      case "de":
        this.langtype = 3;
        break;
      case "fr":
        this.langtype = 4;
        break;
      case "ar":
        this.langtype = 5;
        break;
    }
    this.tabList.forEach((item, index) => {
      item.name = this.$t('breadcrumb.' + item.name)
    })
    let _this = this
    window.addEventListener("setItemEvent", function (e) {
      //e.key : 是值发生变化的key
      if (e.key === "sharing") {
        setTimeout(() => {
          if (e.newValue == '行业动态') {
            _this.tabShou = 1
            _this.companyList(15)
          } else if (e.newValue == '佳智惠百科') {
            _this.tabShou = 2
            _this.companyList(74)
          } else {
            _this.tabShou = 0
            _this.companyList(14)
          }
        }, 500)
      }
    })
  },
  mounted () {
    this.location = this.$t('breadcrumb.bbdt')
    if (this.$route.query.tab) {
      this.addTab(this.tabList[this.$route.query.tab], this.$route.query.tab)
    }
    this.$nextTick(() => {
      // 在dom渲染完后,再执行动画
      this.$wow.init();
    });
    this.companyList(14)
    this.getTopImg()
  },
  methods: {
    handleClickSlide () {
      // console.log(222);
    },
    async getTopImg () {
      let information = await this.api.get(this.apiPath.information, {});
      console.log(information);
      this.information = information[0]
      let res = await this.api.get(this.apiPath.dynamicSwiper, {});
      console.log(res)
      this.swiperList = res;
      if (res.items.length == 0) {
        this.swiperList.items.push({
          img: res.img
        })
      }

    },
    async companyList (id) {
      let data = {
        layout_id: id,
        page: this.page,
        count: this.count
      }
      let companyDynamic = await this.api.post(this.apiPath.editor, data);
      this.dynamic = companyDynamic.items
      this.dynamics = companyDynamic
      console.log(this.dynamics)
      this.total = companyDynamic.total
      this.currentPage1 = companyDynamic.page + 1
    },
    addTab (item, index) {
      //tab切换
      this.location = item.name
      console.log(this.location)
      this.tabShou = index
      this.page = 1
      this.getList()
    },
    handleSizeChange (val) {
      // 每页多少条
      this.count = val
      this.getList()
    },
    handleCurrentChange (val) {
      //当前页
      this.page = val
      this.getList()
    },
    getList () {
      //判断当前tab获取相应数据
      if (this.tabShou == 0) {
        this.companyList(14)
      } else if (this.tabShou == 1) {
        this.companyList(15)
      } else {
        this.companyList(74)
      }
    }
  },
  watch: {}
}
</script>

<style lang="less" scoped>
.pic {
  img {
    width: 100%;
  }
}
.center {
  padding: 20px 100px;
  .news-box {
    width: 100%;
    margin-top: 10px;
    border-top: 1px solid #eee;
    padding-top: 20px;
    .tabs {
      text-align: center;
      .title {
        width: 200px;
        height: 50px;
        line-height: 50px;
        background: #7f0114;
        font-size: 25px;
        font-weight: bold;
        margin-bottom: 5px;
        color: #fff;
      }
      ul {
        display: flex;
        border-bottom: 1px solid #7f0114;
        margin-bottom: 40px;
      }
      ul li {
        width: 100px;
        height: 40px;
        line-height: 40px;
        color: #000;
        font-size: 18px;
        cursor: pointer;
      }
      ul .active {
        background: #7f0114;
        color: #fff;
        transition: 0.5s;
      }
      img {
        width: 200px;
        height: 150px;
      }
    }
    .news-cen {
      width: 100%;
      margin: 0 auto;
    }
  }
}
@media screen and (max-width: 750px) {
  .img img {
    height: 200px;
    object-fit: cover;
  }
  .breadcrumb {
    display: none;
  }
  .center {
    padding: 0;
  }
}
</style>
